import React from 'react';

const Linkedin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
  <g clipPath="url(#clip0_8446_8784)">
    <path d="M8.39999 10.7999V19.7999M19.6 19.7999V15.2999C19.6 14.3451 19.2067 13.4294 18.5065 12.7543C17.8064 12.0792 16.8568 11.6999 15.8667 11.6999C14.8765 11.6999 13.9269 12.0792 13.2268 12.7543C12.5267 13.4294 12.1333 14.3451 12.1333 15.2999V19.7999V10.7999M7.46665 8.0999H9.33332M2.79999 0.899902H25.2C25.6951 0.899902 26.1699 1.08954 26.5199 1.42711C26.87 1.76468 27.0667 2.22251 27.0667 2.6999V24.2999C27.0667 24.7773 26.87 25.2351 26.5199 25.5727C26.1699 25.9103 25.6951 26.0999 25.2 26.0999H2.79999C2.30491 26.0999 1.83012 25.9103 1.48005 25.5727C1.12999 25.2351 0.933319 24.7773 0.933319 24.2999V2.6999C0.933319 2.22251 1.12999 1.76468 1.48005 1.42711C1.83012 1.08954 2.30491 0.899902 2.79999 0.899902Z" stroke="white" strokeWidth="1.2" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8446_8784">
      <rect width="28" height="27" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

export default Linkedin;
