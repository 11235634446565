import React from 'react';

const Instagaram = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
        <path d="M22.3634 25H3.63657C2.67209 25 1.74711 24.6316 1.06513 23.9758C0.383137 23.3201 0 22.4307 0 21.5033V3.4967C0 2.56932 0.383137 1.67992 1.06513 1.02416C1.74711 0.368401 2.67209 0 3.63657 0H22.3634C23.3279 0 24.2529 0.368401 24.9349 1.02416C25.6169 1.67992 26 2.56932 26 3.4967V21.5033C26 22.4307 25.6169 23.3201 24.9349 23.9758C24.2529 24.6316 23.3279 25 22.3634 25ZM3.63657 1.39868C3.05788 1.39868 2.5029 1.61972 2.0937 2.01318C1.68451 2.40663 1.45463 2.94027 1.45463 3.4967V21.5033C1.45463 22.0597 1.68451 22.5934 2.0937 22.9868C2.5029 23.3803 3.05788 23.6013 3.63657 23.6013H22.3634C22.9421 23.6013 23.4971 23.3803 23.9063 22.9868C24.3155 22.5934 24.5454 22.0597 24.5454 21.5033V3.4967C24.5454 2.94027 24.3155 2.40663 23.9063 2.01318C23.4971 1.61972 22.9421 1.39868 22.3634 1.39868H3.63657Z" fill="white"/>
        <path d="M12.9935 19C11.807 18.9987 10.6476 18.6457 9.6617 17.9856C8.67583 17.3254 7.90782 16.3878 7.45473 15.2913C7.00164 14.1947 6.88383 12.9885 7.11618 11.825C7.34854 10.6615 7.92063 9.593 8.76013 8.75459C9.59964 7.91618 10.6689 7.34549 11.8327 7.11466C12.9965 6.88382 14.2026 7.00321 15.2985 7.45772C16.3945 7.91224 17.3311 8.68148 17.9899 9.66821C18.6487 10.6549 19.0003 11.8148 19 13.0013C18.9979 14.593 18.3642 16.1188 17.238 17.2435C16.1117 18.3682 14.5851 19 12.9935 19ZM12.9935 8.30054C12.0656 8.30183 11.1589 8.57808 10.388 9.09438C9.617 9.61069 9.01634 10.3439 8.66185 11.2014C8.30737 12.0589 8.21495 13.0022 8.39629 13.9122C8.57763 14.8222 9.02458 15.658 9.68068 16.3141C10.3368 16.9702 11.1726 17.4172 12.0826 17.5985C12.9926 17.7798 13.9359 17.6874 14.7934 17.3329C15.6509 16.9784 16.3841 16.3778 16.9004 15.6068C17.4167 14.8359 17.6929 13.9292 17.6942 13.0013C17.6942 11.7546 17.199 10.5589 16.3174 9.67736C15.4358 8.7958 14.2402 8.30054 12.9935 8.30054Z" fill="white"/>
        <path d="M20 6C20.5523 6 21 5.55228 21 5C21 4.44772 20.5523 4 20 4C19.4477 4 19 4.44772 19 5C19 5.55228 19.4477 6 20 6Z" fill="white"/>
    </svg>
);

export default Instagaram;
