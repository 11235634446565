import React from "react";
import { graphql, Link } from "gatsby";
import Cta from "../components/service/Cta";
// import KnowMoreSection from "../components/KnowMoreSection";
import { GatsbyImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import Linkedin from "../images/linkedin";
import Instagaram from "../images/instagaram";
export default function AuthorDetail({ data }) {
  const author = data?.allWpUser.edges[0].node;
  // const hashtags = author?.userInfo?.hashtags;
  const blogsContributed = data?.allWpPost?.edges.slice(0, 3);
  const dataCta = { title: "Let’s Take Your Product <br /> to Newer Heights" };
  return (
    <>
      {author?.description && (
        <>
          <Seo title={'Founder & Managing Director | Octet Design Studio'} description={'Octet employs design thinking and a lean approach to transform concepts into compelling products for clients in diverse sectors.'} />
          <Layout>
            <section className="lg:py-[120px] md:py-[100px] py-[80px] text-white bg-[#121212] author-container">
              <div className="container">
                <div className="lg:mb-[30px] mb-[40px]">
                  <h1 className="lg:text-[95px] relative md:text-[80px] text-[50px] leading-[1.2] tracking-[.08em] md:leading-[1.3684] mb-[20px] lg:mb-[30px] font-heading">
                    {author.name}
                  </h1>
                  <p className="lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] font-medium uppercase">
                    {author.userInfo.position}
                  </p>
                </div>
                <div className="flex lg:gap-[70px] md:gap-[50px] gap-[40px] author">
                  <div className="flex flex-col gap-[20px] details">
                    {/* <ul className="flex md:gap-[30px] gap-[20px] flex-wrap">
                        {hashtags && hashtags.map((hashtag, index) => (
                            <li key={index} className="lg:text-[14px] md:text-[15px] md:leading-[30px] font-medium lg:leading-[22px] uppercase tracking-[.03em]">#{hashtag.addHashtag}</li>
                        ))}
                    </ul> */}
                    <div className="flex gap-[20px] flex-wrap author-links">
                      {
                        author?.seo.social?.linkedIn &&
                        <a
                          href={author?.seo.social?.linkedIn}
                          className="lg:text-[14px] md:text-[14px] text-[12px] font-medium leading-[22px] tracking-[.05em] uppercase hover:text-[black]"
                        >
                          <Linkedin />
                        </a>
                      }
                      {
                        author?.seo.social?.instagram &&
                        <a
                          href={author?.seo.social?.instagram}
                          className="lg:text-[14px] md:text-[14px] text-[12px] font-medium leading-[22px] tracking-[.05em] uppercase hover:text-[black]"
                        >
                          <Instagaram />
                        </a>
                      }
                    </div>
                    <p
                      className="lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] font-300"
                      dangerouslySetInnerHTML={{ __html: author.description }}
                    ></p>
                  </div>
                  <div className="min-w-[458px h-[550px] author-image">
                    <img
                      src={'https://octet-gatsby.in2.cdn-alpha.com/wp-content/uploads/2024/01/image-1.png'}
                      alt={'Creative Director and Founder of Octet Design Studio- Aakash Jethwani'}
                      title="Aakash Jethwani"
                      className="w-[100%] max-w-[100%] h-[100%] object-cover"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="lg:py-[200px] md:py-[120px] py-[80px]">
              <div className="container">
                <h2 className="md:mb-[60px] mb-[40px] text-[38px] leading-[51px] md:text-[50px] font-heading font-medium lg:text-[60px] md:leading-[68px] lg:leading-[84px] tracking-[.08em] lg:tracking-[.01em]">
                  Contributed Blogs
                </h2>
                <div className="flex lg:gap-[100px] md:gap-[80px] gap-[60px] flex-col blog-list">
                  {blogsContributed?.map(({ node }) => (
                      <Link
                      to={`/${node.slug}/`}
                      className="lg:pb-[70px] blog-link-tag mb-[70px] md:pb-[60px] pb-[80px] blog-div flex gap-[30px] lg:gap-[0] justify-between md:items-center flex-col md:flex-row"
                      key={node.id}
                    >
                      <div className="max-w-[600px] md:w-[80%] w-[100%]">
                        <h2 className="md:text-[34px] text-unset lg:leading-[56px] text-[26px] leading-[36px] font-semibold mb-[10px]">
                          {node.title}
                        </h2>
                        <div
                          className="eliple ibm lg:line-clamp-2 line-clamp-4 lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] mb-[35px]"
                          dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        ></div>
                      </div>
                      <div className="md:w-[327px] w-[100%] blog-list-image">
                        {node.featuredImage && node.featuredImage.node && (
                          <>
                            <GatsbyImage
                              image={node.featuredImage.node.gatsbyImage}
                              alt={
                                node.featuredImage.node.altText
                                  ? node.featuredImage.node.altText
                                  : "blogs"
                              }
                              title={node.featuredImage.node.title}
                            />
                          </>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </section>
            <Cta data={dataCta} />
            {/* <KnowMoreSection nin="Contact Us" />{" "} */}
          </Layout>
        </>
      )}
    </>
  );
}

export const query = graphql`
  query ($slug: String!, $name: String!) {
    allWpUser(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          description
          avatar {
            url
          }
          name
          seo {
            metaDesc
            title
            social {
              linkedIn
              instagram
            }
          }
          userInfo {
            position
          }
        }
      }
    }
    allWpPost(
      filter: { author: { node: { name: { eq: $name } } } }
      sort: { date: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 628, placeholder: BLURRED)
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`;
